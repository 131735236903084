import { DiveContextProvider } from './contexts/DiveContext';
import { Outlet } from 'react-router-dom';
import Navigation from './components/navigation/Navigation';
import { useLocation } from 'react-router-dom';

const Root = () => {
    const location = useLocation();
    const hideNavigation = location.pathname === '/dive/outro' || location.pathname.startsWith('/explore');
    return (
        <DiveContextProvider >
            { !hideNavigation  && <Navigation  />}
            <Outlet />
        </DiveContextProvider>
    );
};

export default Root;
