import axios from 'axios';

const baseURL = process.env.REACT_APP_API_URL || 'http://localhost/api';

const axiosClient = axios.create({
    baseURL,
    headers: {
        'Content-type': 'application/json',
    },
});

export { axiosClient };
