import { Outlet, useBeforeUnload, useNavigate } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { AppContext } from '../contexts/AppContext';
import Navigation from './navigation/Navigation';
import Header from './header/Header';
import Grid from '../../shared-kernel/components/grid/Grid';
import Column from '../../shared-kernel/components/grid/Column';

const Layout = () => {
    const { showNavigation, User, dives, updateShowNavigation, isLoading } = useContext(AppContext);
    const navigate = useNavigate();

    useBeforeUnload(() => {
        localStorage.setItem('isReload', 'true');
    });

    useEffect(() => {
        if (isLoading) {
            return;
        }

        if (User && dives.length > 0) {
            updateShowNavigation(true);

            if (User.currentDive >= dives.length) {
                navigate(`/dive/outro`);
            } else if (User.currentDive === 0) {
                navigate('/intro');
                localStorage.removeItem('isReload');
            } else {
                if (localStorage.getItem('isReload')) {
                    navigate('/dive/continue');
                } else {
                    navigate('/dive/scenario');
                }
                localStorage.removeItem('isReload');
            }
        } else {
            navigate('/registration/welcome');
        }

        return () => {
            updateShowNavigation(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading]);

    return (
        <>
            <Header />

            <Grid>
                <Column columns={1}>
                    {showNavigation && <Navigation />}
                </Column>
                <Column columns={12} offset={3}>
                    <Outlet />
                </Column>
            </Grid>
        </>
    );
};

export default Layout;
